import dayjs, { addIsSameOrBeforePlugin } from "~/lib/dayjs";
import { useReport } from "~/composables/useReport";
import { getFromUserStorage, saveToUserStorage } from "~/helpers/userStorage";
import { retryablePromise } from "~/helpers/retryablePromise";
import { DEFAULT_ERROR_MESSAGE } from "~/constants";

const storageKeyLastUpdate = "hungryhub_group_landing_last_updated";
const storageKey = "hungryhub_group_landing";

function getLastUpdate() {
  const { success, data } = getFromUserStorage({
    key: storageKeyLastUpdate,
    type: "localstorage",
  });
  return success ? (data as string) : "";
}

function setLastUpdate() {
  const val = dayjs().format("DD-MM-YYYY HH:ss");
  saveToUserStorage({ key: storageKeyLastUpdate, type: "localstorage", val });
}

async function shouldGetAllGroupLanding() {
  try {
    const getStoredGroupLanding = getFromUserStorage({
      key: storageKey,
      type: "localstorage",
    });
    await retryablePromise(() => addIsSameOrBeforePlugin());
    if (
      Array.isArray(getStoredGroupLanding.data) &&
      getStoredGroupLanding.data.length === 0
    ) {
      return true;
    }
    const today = dayjs();
    const savedLastUpdated = getLastUpdate();
    const lastUpdated = dayjs(savedLastUpdated, "DD-MM-YYYY HH:ss");
    const isLastUpdatedValid = lastUpdated.isValid();
    const isLastUpdatedExpired = () => {
      if (!isLastUpdatedValid) {
        return true;
      }
      return today.isSameOrBefore(lastUpdated, "hour") === false;
    };

    return isLastUpdatedExpired();
  } catch (err) {
    useReport({
      errorException: err,
      level: "error",
      message: `${DEFAULT_ERROR_MESSAGE}, failed determine shouldGetAllGroupLanding `,
    });
    return true;
  }
}

export async function getAllGroupLanding() {
  try {
    const { getGroupLanding } = await retryablePromise(
      () => import("~/api/group_landing/getGroupLanding")
    );
    const useGroupLandingStore = (
      await retryablePromise(() => import("~/stores/groupLanding"))
    ).default;
    const groupLandingStore = useGroupLandingStore();
    const allow = await shouldGetAllGroupLanding();
    if (allow) {
      const { isSuccess, message, data } = await getGroupLanding();
      if (!isSuccess || !data) {
        useReport({
          level: "error",
          message,
        });
        return {
          isSuccess,
          message,
          data,
        };
      }
      setLastUpdate();
      groupLandingStore.groupLandingList = Array.isArray(data) ? data : [data];
      saveToUserStorage({
        key: storageKey,
        type: "localstorage",
        val: groupLandingStore.groupLandingList,
      });
      return {
        isSuccess,
        message,
        data,
      };
    }
    return null;
  } catch (err) {
    useReport({
      errorException: err,
      level: "error",
      message: "failed getAllGroupLanding",
    });
    return null;
  }
}
